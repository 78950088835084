import request from '@/api/index'
/**
 * @desc 登录方法
 * @param {Object} params
 */
export const fn_api__auth_token = params => {
  return request({
    url: '/park-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': params.tenantId
    },
    params: {
      grant_type: 'password',
      scope: 'all',
      ...params
    }
  })
}
// 刷新方法
export const fn_api__refresh_token = (refresh_token, tenantId) => {
  return request({
    url: '/park-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId
    },
    params: {
      tenantId,
      refresh_token,
      grant_type: 'refresh_token',
      scope: 'all'
    }
  })
}
// 注册
export const fn_api__register = data => {
  return request({
    url: '/logout',
    method: 'post',
    data
  })
}
// 找回密码
export const fn_api__forgetPass = data => {
  return request({
    url: '/logout',
    method: 'post',
    data
  })
}
// 获取验证码
export const fn_api__get_code = email => {
  return request({
    url: '/logout',
    method: 'get',
    params: { email }
  })
}
// 退出方法
export const fn_api__logout = () => {
  return request({
    url: '/park-auth/oauth/logout',
    method: 'get'
  })
}

// 获取用户详细信息
export const fn_api__get_info = () => {
  return request({
    url: '/park-user/info',
    method: 'get'
  })
}

// 获取社区列表
export const fn_api_get_parklist = () => {
  return request({
    url: '/park-manage/parkInfo/getAll',
    method: 'get'
  })
}

// 获取所有停车场
export const fn_api__get_parkArea = () => {
  return request({
    url: '/park-manage',
    method: 'get'
  })
}

// 获取路由
export const fn_api__get_routers = () => {
  return request({
    url: '/park-system/menu/routes',
    method: 'get'
  })
}
// 获取Button权限
export const fn_api__get_buttons = () => {
  return request({
    url: '/park-system/menu/buttons',
    method: 'get'
  })
}
// 获取顶部菜单
export const fn_api__get_topMenu = () => {
  return request({
    url: '/park-system/menu/top-menu',
    method: 'get'
  })
}
// 根据顶部菜单ID获取顶部菜单路由
export const fn_api__get_topRoutes = topMenuId =>
  request({
    url: '/park-system/menu/routes',
    method: 'get',
    params: {
      topMenuId
    }
  })
