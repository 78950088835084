/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-09-03 14:40:30
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:44:55
 */
import request from '@/api/index'
const park_screen = '/park-manage'
const parking_screen = '/parking-service'

/**
 * @desc 社区首页信息
 * @param {Object} data
 */
export const fn_api__get_park_info = params => {
  return request({
    // url: `/park-manage/screenV2/parkInfo`, //本地
    url: `${park_screen}/screenV2/parkInfo`,
    method: 'get',
    params
  })
}

/**
 * @desc 停车场信息
 * @param {Object} data
 */
export const fn_api__get_parking_info = data => {
  return request({
    url: `${parking_screen}/screenV2/parking/getParkingInfo`,
    method: 'get',
    data
  })
}

/**
 * @desc 首页环境详细信息
 * @param {Object} data
 */
export const fn_api_get_park_environ_detail = data => {
  return request({
    url: `${park_screen}/screen01/environInfo/getEnvironAll`,
    method: 'get',
    data
  })
}

/**
 * @desc 设备详情信息
 * @param {Object} data
 */
export const fn_api_get_park_device_detail = params => {
  return request({
    url: `${park_screen}/screen01/equipmentInfo/getFacilityAll`,
    method: 'get',
    params
  })
}

/**
 * @desc 人员详情信息
 * @param {Object} data
 */
export const fn_api_get_park_peoples_detail = params => {
  return request({
    url: `${park_screen}/screen01/parkPeoplesInfo/getPeoplesAll`,
    method: 'get',
    params
  })
}

/**
 * @desc 告警详情信息
 * @param {Object} data
 */
export const fn_api_get_park_alarm_detail = params => {
  return request({
    url: `${park_screen}/screen00/alarm/details`,
    method: 'get',
    params
  })
}
/**
 * @desc 车辆详情信息
 * @param {Object} params
 */
export const fn_api_get_park_parking_detail = params => {
  return request({
    url: `${parking_screen}/screenV2/parking/details`,
    method: 'get',
    params
  })
}

/**
 * @desc 能耗详情信息
 * @param {Object} data
 */
export const fn_api_get_park_energy_detail = params => {
  return request({
    url: `${park_screen}/screen00/energyConsumption/details`,
    method: 'get',
    params
  })
}

/**
 * @desc 告警列表 （右上角）
 * @param {Object} data
 */
export const fn_api_get_park_alarm_all = params => {
  return request({
    url: `${park_screen}/screenV2/info/warningPage`,
    method: 'get',
    params
  })
}

/**
 * @desc 告警列表 删除记录
 * @param {Object}
 */
export const fn_api_get_park_alarm_remove = params => {
  return request({
    url: `${park_screen}/info/remove`,
    method: 'delete',
    params
  })
}

/**
 * @desc 首页告警列表
 * @param {Object}
 */
export const fn_api_get_park_alarm_list = params => {
  return request({
    url: `${park_screen}/info/list`,
    method: 'get',
    params
  })
}
